
@font-face {
  font-family: 'font-calligraphy';
  src: url('../src/assets/fonts/antonellie_calligraphy/Antonellie\ Callygraphy\ Demo.ttf') format('truetype');
  /* Asegúrate de que la ruta sea correcta según la ubicación de tu archivo .ttf */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'lemon';
  src: url('../src/assets/fonts/lemon_milk/LEMONMILK-Bold.otf') format('truetype');
  /* Asegúrate de que la ruta sea correcta según la ubicación de tu archivo .ttf */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'louis';
  src: url('../src/assets/fonts/louis_george_cafe/Louis\ George\ Cafe\ Bold.ttf') format('truetype');
  /* Asegúrate de que la ruta sea correcta según la ubicación de tu archivo .ttf */
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-cielo-deg {
  background-image: linear-gradient(
      rgba(43, 43, 81, 0.808), 
      rgba(0, 0, 255, 0.846)
    ),url('../src/assets//pagina\ il\ cielofondo1.png');
  background-size: cover;
  background-position: center;
}

.bg-experiences {
  background: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), 
    url('../src/assets/portada.jpeg');
  background-size: cover;
  background-position: center;
  height: 45rem;
}

.bg-menu{
  background: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), 
    url('../src/assets/pagina\ il\ cielo\ fondo\ natural1.jpg');
  background-size: cover;
  background-position: center;
}

.bg-kitchen {
  background: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), 
    url('../src/assets/fondokitchen.jpeg');
  background-size: cover;
  background-position: center;
}

.bg-greca {
  background-color: #28254e;
}

.bg-patasalada {
  background: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), 
    url('https://storage.cloud.google.com/corlosqui.appspot.com/secondslider.jpg');
  background-size: cover;
  background-position: center;
}

.bg-events-header {
  background: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), 
    url('../src//assets//Reservations.jpg');
  background-size: cover;
  background-position: center;
  height: 45rem;
  
}

.gradient-background {
  background: linear-gradient(
    to right,
    rgba(0, 0, 255, 0.5), /* Azul con 50% de opacidad */
    rgba(0, 123, 255, 0.5) /* Azul claro con 50% de opacidad */
  );
  padding: 20px;
  color: white;
  text-align: center;
  border-radius: 8px;
}

.tab-button {
  background: linear-gradient(135deg, #6EE7B7, #a2c2e6); /* Degradado para los tabs */
  border: none;
  color: white;
  padding: 10px 20px;
  margin: 0 0.5rem; /* Margen horizontal entre los botones */
  font-size: 1.2rem;
  cursor: pointer;
  transition: background 0.3s ease;
  text-align: center;
}

.tab-button.active {
  background: linear-gradient(135deg, #3B82F6, #9333EA); /* Degradado más intenso para el tab activo */
}

.tab-button:hover {
  background: linear-gradient(135deg, #1D4ED8, #4F46E5); /* Cambio de color en hover */
}

/* Ajustes para dispositivos móviles */
@media (min-width: 768px) {
  .tab-button {
    margin: 0; /* Elimina el margen horizontal en pantallas grandes */
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
}